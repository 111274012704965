<template>
<div class="wrapper" v-loading.fullscreen.lock="loading" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="wp-body"> 
            <div class="top-header">
                <div class="flex items-center gap-3">
                    <button class="bg-white p-1 w-8 h-8 rounded-xl" @click="$router.go(-1)">
                        <i class="el-icon-arrow-left"></i>
                    </button>
                    <h1> {{ $t('transport_facility_info') }}</h1>
                </div>
            </div>
            <div class="general-info">
                <div class="general-info-title">
                    {{$t('transport_general_info')}}
                </div>
                <hr>
                <el-collapse v-model="activeNames">
                    <el-form :model="form" :rules="formrules" ref="form1" class="Form">
                        <div class="input-section">
                            <el-row :gutter="30">
                                <el-col :md="8">
                                    <label class="item">
                                        <p> {{$t('transport_facility_pass')}} </p>
                                        <el-form-item prop="shifr">
                                            <el-input name="shifr" :disabled="disabled_input" v-model="form.shifr" :placeholder="$t('transport_facility_pass')" clearable />
                                        </el-form-item>
                                    </label>
                                    <label class="item">
                                        <p> {{$t('transport_facility_type')}} </p>
                                        <el-form-item prop="type_transport_facility">
                                            <el-select :disabled="disabled_input" v-model="form.type_transport_facility" class="w-full" clearable :placeholder="$t('transport_facility_type')">
                                                <el-option v-for="(item, i) in dictionary.type_transport_facility" :key="i" :label="item.name_cyr" :value="item.id">
                                                </el-option>
                                            </el-select>

                                        </el-form-item>
                                    </label>
                                    <div class="coll-title">
                                        {{ $t('contact_infos') }}
                                    </div>
                                    <label class="item" style="margin-top: 10px">
                                        <el-form-item class="item-transport" prop="phone" style="">
                                            <div class="coll-icon">
                                                <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.02529 1.67159L5.67029 3.19184C5.95079 3.85409 5.79479 4.63334 5.28479 5.11784L3.86354 6.46634C3.95129 7.27334 4.22279 8.06759 4.67729 8.84984C5.10899 9.60566 5.68791 10.2672 6.37979 10.7953L8.08679 10.2261C8.73404 10.0108 9.43829 10.2583 9.83429 10.8411L10.759 12.1986C11.2203 12.8766 11.137 13.8111 10.5648 14.3863L9.95204 15.0013C9.34154 15.6148 8.46929 15.8368 7.66304 15.5856C5.75804 14.9916 4.00829 13.2276 2.41079 10.2951C0.811038 7.35809 0.246287 4.86584 0.717287 2.81909C0.915287 1.95809 1.52804 1.27184 2.32904 1.01609L3.13604 0.758088C3.89279 0.516588 4.70129 0.907338 5.02529 1.67159ZM10.861 0.689088L10.9375 0.684588C11.0735 0.684594 11.2048 0.73382 11.3072 0.823163C11.4097 0.912506 11.4763 1.03592 11.4948 1.17059L11.5 1.24709V3.68759H13.939C14.0751 3.68762 14.2065 3.73695 14.309 3.82645C14.4114 3.91594 14.478 4.03954 14.4963 4.17434L14.5015 4.25084C14.5015 4.38677 14.4523 4.51809 14.363 4.62054C14.2736 4.72298 14.1502 4.7896 14.0155 4.80809L13.939 4.81334H11.5V7.24934C11.5 7.38527 11.4508 7.51659 11.3615 7.61904C11.2721 7.72148 11.1487 7.7881 11.014 7.80659L10.9375 7.81184C10.8016 7.81183 10.6703 7.76261 10.5678 7.67326C10.4654 7.58392 10.3988 7.4605 10.3803 7.32584L10.375 7.24934V4.81259H7.93604C7.80022 4.81261 7.66899 4.76349 7.56656 4.6743C7.46414 4.5851 7.39744 4.46187 7.37879 4.32734L7.37354 4.25084C7.37357 4.1148 7.4229 3.98338 7.5124 3.88092C7.60189 3.77846 7.72549 3.71191 7.86029 3.69359L7.93604 3.68759H10.375V1.24634C10.375 1.11041 10.4243 0.979081 10.5136 0.87664C10.603 0.774199 10.7264 0.707574 10.861 0.689088L10.9375 0.683838L10.861 0.689088Z" fill="#314E52" />
                                                </svg>
                                            </div>
                                            <el-input :disabled="disabled_input" class="coll-place" v-model="form.phone" v-mask="`+998 ## ###-##-##`" placeholder="+998 99 ___-__-__" clearable />
                                        </el-form-item>
                                    </label>
                                    <label class="item">
                                        <el-form-item class="item-transport" prop="email">
                                            <div class="coll-icon">
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M14.6084 12.3594C14.6084 13.1994 14.8474 13.5344 15.4724 13.5344C16.8654 13.5344 17.7524 11.7594 17.7524 8.80739C17.7524 4.29539 14.4644 2.13539 10.3594 2.13539C6.13644 2.13539 2.29544 4.96739 2.29544 10.3194C2.29544 15.4314 5.65544 18.2154 10.8154 18.2154C12.5674 18.2154 13.7434 18.0234 15.5424 17.4234L15.9284 19.0304C14.1524 19.6074 12.2544 19.7744 10.7914 19.7744C4.02344 19.7744 0.398438 16.0544 0.398438 10.3184C0.398438 4.53439 4.59944 0.598389 10.3834 0.598389C16.4074 0.598389 19.5984 4.19839 19.5984 8.61439C19.5984 12.3584 18.4234 15.2144 14.7274 15.2144C13.0464 15.2144 11.9434 14.5424 11.7994 13.0534C11.3674 14.7094 10.2154 15.2144 8.65444 15.2144C6.56644 15.2144 4.81444 13.6054 4.81444 10.3664C4.81444 7.10239 6.35144 5.08639 9.11144 5.08639C10.5754 5.08639 11.4874 5.66239 11.8934 6.57439L12.5904 5.30239H14.6064V12.3594H14.6084ZM11.6574 9.19139C11.6574 7.87239 10.6724 7.31939 9.85644 7.31939C8.96844 7.31939 7.98544 8.03839 7.98544 10.1514C7.98544 11.8314 8.72944 12.7674 9.85644 12.7674C10.6484 12.7674 11.6574 12.2634 11.6574 10.8714V9.19139Z" fill="#314E52" />
                                                </svg>
                                            </div>
                                            <el-input :disabled="disabled_input" class="coll-place" v-model="form.email" placeholder="Электрон почта" clearable />
                                        </el-form-item>
                                    </label>
                                    <label class="item">
                                        <el-form-item class="item-transport" prop="website">
                                            <div class="coll-icon">
                                                <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M18.4996 5.71388C17.6759 4.48075 16.5602 3.47031 15.2517 2.77246C13.9432 2.0746 12.4826 1.71096 10.9996 1.71388C9.51928 1.71298 8.0616 2.07761 6.75603 2.7754C5.45046 3.4732 4.33738 4.48256 3.51562 5.71388" stroke="#314E52" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M10.4996 1.71387C9.72025 2.96247 9.10645 4.30706 8.67362 5.71387M11.4996 1.71387C12.28 2.96362 12.8945 4.30958 13.3276 5.71787M18.4996 15.7139C17.6759 16.947 16.5602 17.9574 15.2517 18.6553C13.9432 19.3532 12.4826 19.7168 10.9996 19.7139C9.51928 19.7148 8.0616 19.3501 6.75603 18.6523C5.45046 17.9546 4.33738 16.9452 3.51562 15.7139" stroke="#314E52" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M10.5 19.7139C9.72063 18.4653 9.10682 17.1207 8.674 15.7139M11.5 19.7139C12.2804 18.4641 12.8949 17.1182 13.328 15.7099M1 8.71387L2 12.7139L3.5 8.71387L5 12.7139L6 8.71387M16 8.71387L17 12.7139L18.5 8.71387L20 12.7139L21 8.71387M8.5 8.71387L9.5 12.7139L11 8.71387L12.5 12.7139L13.5 8.71387" stroke="#314E52" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </div>
                                            <el-input :disabled="disabled_input" class="coll-place" v-model="form.website" placeholder="Веб-сайт" clearable />
                                        </el-form-item>
                                    </label>

                                </el-col>
                                <el-col :md="8">
                                    <label class="item">
                                        <p> {{$t('transport_facility_year')}} </p>
                                        <el-form-item prop="year_construction">
                                            <el-date-picker name="construction_year" :picker-options="pickerOptions" :disabled="disabled_input" :placeholder="$t('construction_year')" style="width: 100%" v-model="form.year_construction" type="year" value-format="yyyy">
                                            </el-date-picker>
                                        </el-form-item>
                                    </label>
                                    <label class="item">
                                        <p> {{$t('transport_facility_road_category')}} </p>
                                        <el-form-item prop="road_category">
                                            <el-select :disabled="disabled_input" v-model="form.road_category" class="w-full" clearable :placeholder="$t('transport_facility_road_category')">
                                                <el-option v-for="(item, i) in dictionary.road_category" :key="i" :label="item.name_cyr" :value="item.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </label>
                                    <div class="obstacle">
                                        <div class="obstacle-img-input">
                                            <div class="obstacle-img">
                                                <img src="../../../assets/img/icons/obstacle.svg" alt="">
                                            </div>
                                            <div class="obstacle-input">
                                                <label class="item">
                                                    <p> {{$t('transport_facility_type_barrier')}}</p>
                                                    <el-form-item prop="barrier_type">
                                                        <el-select :disabled="disabled_input" v-model="form.barrier.barrier_type" class="w-full" clearable :placeholder="$t('transport_facility_type_barrier')">
                                                            <el-option v-for="(item,i) in dictionary.barrier_type" :key="i" :label="item.name_cyr" :value="item.id">
                                                            </el-option>
                                                        </el-select>
                                                    </el-form-item>
                                                </label>
                                                <label class="item" v-if="form.barrier.barrier_type==1">
                                                    <p>{{ $t('type_water_body') }}</p>
                                                    <el-form-item prop="barrier_type">
                                                        <el-select :disabled="disabled_input" v-model="form.barrier.water_basin" class="w-full" clearable :placeholder="$t('type_water_body')">
                                                            <el-option v-for="(item, i) in dictionary.water_basin" :key="i" :label="item.name_cyr" :value="item.id">
                                                            </el-option>
                                                        </el-select>
                                                    </el-form-item>
                                                </label>
                                                <label class="item">
                                                    <p> {{$t('transport_facility_name_barrier')}} </p>
                                                    <el-form-item prop="water_basin">
                                                        <el-input :disabled="disabled_input" v-model="form.barrier.barrier_name" :placeholder="$t('transport_facility_name_barrier')" clearable />
                                                    </el-form-item>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </el-col>
                                <el-col :md="8">
                                    <div class="transport-location">
                                        <div class="transport-location-img">
                                            <img src="../../../assets/img/icons/location.svg" alt="">
                                        </div>
                                        <div class="transport-location-input">
                                            <label class="item">
                                                <p> {{$t('transport_region')}} </p>
                                                <el-form-item prop="region">
                                                    <el-select :disabled="disabled_input" v-model="form.region" class="w-full" clearable :placeholder="$t('transport_region')">
                                                        <el-option v-for="(item, i) in dictionary.region" :key="i" :label="item.name_cyr" :value="item.id">
                                                        </el-option>
                                                    </el-select>
                                                </el-form-item>
                                            </label>
                                            <label class="item">
                                                <p>{{ $t('city_or_district') }}</p>
                                                <el-form-item prop="district">
                                                    <el-select :disabled="disabled_input" v-model="form.district" class="w-full" clearable :placeholder="$t('city_or_district')">
                                                        <el-option v-for="(item, i) in districts" :key="i" :label="item.name_cyr" :value="item.id">
                                                        </el-option>
                                                    </el-select>
                                                </el-form-item>
                                            </label>
                                            <label class="item">
                                                <p> {{ $t('way_number')  }} </p>
                                                <el-form-item prop="road_name">
                                                    <el-select class="w-full" :disabled="disabled_input" v-model="form.road_name" filterable remote :placeholder="`${$t('way_number') }*`" :remote-method="remoteMethod" :loading="selLoading">
                                                        <el-option v-for="(item,i) in options" :key="i" :label="item.number" :value="item.id">
                                                        </el-option>
                                                    </el-select>
                                                </el-form-item>
                                            </label>
                                            <label class="item">
                                                <p> {{$t('transport_road_name')}} </p>
                                                <el-form-item>
                                                    <el-input disabled v-model="road_name" :placeholder="$t('transport_road_name')" />
                                                </el-form-item>
                                            </label>
                                            <label class="item" v-if="road_name_options.length">
                                                <p> {{ $t('way_name_category')  }} </p>
                                                <el-form-item prop="road_name_option">
                                                    <el-select class="w-full" :disabled="!form.road_name || disabled_input" v-model="form.road_name_option" :placeholder="`*${$t('way_name_category')}`" multiple>
                                                        <el-option v-for="(item,i) in road_name_options" :key="i" :label="item.name" :value="item.id">
                                                        </el-option>
                                                    </el-select>
                                                </el-form-item>
                                            </label>
                                            <label class="item" v-else>
                                                <p> {{ $t('way_name_category')  }} </p>
                                                <el-form-item>
                                                    <el-input :disabled="true" v-model="value" :placeholder="$t('not_valid')" clearable />
                                                </el-form-item>
                                            </label>
                                            <label class="item">
                                                <p> {{$t('transport_km')}} </p>
                                                <el-form-item prop="km">
                                                    <el-input :disabled="disabled_input" v-model="form.km" :placeholder="`${$t('transport_km')}`" clearable />
                                                </el-form-item>
                                            </label>
                                        </div>
                                    </div>
                                    <label class="item">
                                        <p> {{$t('transport_geo')}} </p>
                                        <el-form-item prop="geolocation">
                                            <div class="flex align-top">
                                                <el-input :disabled="disabled_input" style="width: 100%" v-model="coor" :placeholder="$t('coor')" />
                                            </div>
                                            <div style="position: relative; overflow: hidden; margin-top: 10px;" class="flex justify-end">
                                                <el-tooltip style="margin:0" effect="dark" :content=" disabled_input ? $t('show_coor'):$t('change_coor')" placement="bottom">
                                                    <el-button class="primary-btn" style="border-radius:5px; font-size:14px; font-size:14px;   padding:15px 20px;" @click="EditMap(form.district)">
                                                        <i class="el-icon-location-outline mr-1"></i>
                                                        <span v-if="disabled_input"> {{$t('show_coor')}} </span>
                                                        <span v-else> {{$t('change_coor')}} </span>
                                                    </el-button>
                                                </el-tooltip>
                                            </div>
                                        </el-form-item>

                                    </label>
                                </el-col>
                            </el-row>

                        </div>
                    </el-form>
                </el-collapse>
            </div> 
        <div>
            <div class="general-info">
                <div class="general-info-title">
                    {{$t('transport_facility_technical_indicators')}}
                </div>
                <hr>
                <el-collapse v-model="activeNames">
                    <el-form :model="form" :rules="formrules" ref="form2" class="Form">
                        <div class="input-section">
                            <el-row :gutter="30">
                                <el-col :md="8">
                                    <label class="item">
                                        <p> {{$t('transport_facility_type_static_scheme')}}</p>
                                        <el-form-item prop="type_static_schema">
                                            <el-select :disabled="disabled_input" v-model="form.type_static_schema" class="w-full" clearable :placeholder="$t('transport_facility_type_static_scheme')">
                                                <el-option v-for="(item, i) in dictionary.type_static_schema" :key="i" :label="item.name_cyr" :value="item.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </label>
                                    <label class="item">
                                        <p> {{$t('transport_facility_type_length')}} </p>
                                        <el-form-item prop="type_by_length">
                                            <el-select :disabled="disabled_input" v-model="form.type_by_length" class="w-full" clearable :placeholder="$t('transport_facility_type_length')">
                                                <el-option v-for="(item, i) in dictionary.type_by_length" :key="i" :label="item.name_cyr" :value="item.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </label>
                                    <label class="item">
                                        <p> {{$t('transport_seismicity_construction_site')}}</p>
                                        <el-form-item prop="seismicity">
                                            <el-select :disabled="disabled_input" v-model="form.seismicity" class="w-full" clearable :placeholder="$t('transport_seismicity_construction_site')">
                                                <el-option v-for="(item, i) in dictionary.buildingseismicity" :key="i" :label="item.number" :value="item.id">
                                                </el-option>
                                            </el-select>

                                        </el-form-item>
                                    </label>
                                    <label class="item">
                                        <p> {{$t('transport_device_material')}} </p>
                                        <el-form-item prop="intermediate_device_material">
                                            <el-select :disabled="disabled_input" v-model="form.intermediate_device_material" class="w-full" clearable :placeholder="$t('transport_device_material')">
                                                <el-option v-for="(item, i) in dictionary.intermediate_device_material" :key="i" :label="item.name_cyr" :value="item.id">
                                                </el-option>
                                            </el-select>

                                        </el-form-item>
                                    </label>
                                    <label class="item">
                                        <p> {{$t('transport_material_support')}} </p>
                                        <el-form-item prop="material_of_support">
                                            <el-select :disabled="disabled_input" v-model="form.material_of_support" class="w-full" clearable :placeholder="$t('transport_material_support')">
                                                <el-option v-for="(item, i) in dictionary.material_of_support" :key="i" :label="item.name_cyr" :value="item.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </label>
                                    <div class="obstacle">
                                        <div class="obstacle-title">
                                            {{$t('transport_facility_dimensions')}}
                                        </div>
                                        <div class="obstacle-img-input">
                                            <div class="obstacle-img">
                                                <img src="../../../assets/img/icons/breadth.svg" alt="">
                                            </div>
                                            <div class="obstacle-input">
                                                <label class="item">
                                                    <p> {{$t('transport_action_part')}} </p>
                                                    <el-form-item prop="size_of_building.traffic_section">
                                                        <el-input :disabled="disabled_input" v-model="form.size_of_building.traffic_section" :placeholder="`${$t('transport_action_part')}`" clearable />
                                                    </el-form-item>
                                                </label>
                                                <label class="item">
                                                    <p> {{$t('transport_width_sidewalks')}} </p>
                                                    <el-form-item prop="size_of_building.width_of_sidewalks">
                                                        <el-input :disabled="disabled_input" v-model="form.size_of_building.width_of_sidewalks" :placeholder="`${$t('transport_width_sidewalks')}`" clearable />
                                                    </el-form-item>
                                                </label>
                                                <label class="item">
                                                    <p> {{$t('transport_meet_requirements')}} </p>
                                                    <el-form-item prop="size_of_building.meet_the_requirements">
                                                        <el-select :disabled="disabled_input" v-model="form.size_of_building.meet_the_requirements" class="w-full" clearable :placeholder="$t('transport_meet_requirements')">
                                                            <el-option v-for="(item, i) in dictionary.meet_requirements" :key="i" :label="item.name_cyr" :value="item.id">
                                                            </el-option>
                                                        </el-select>
                                                    </el-form-item>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </el-col>
                                <el-col :md="8">
                                    <div class="obstacle">
                                        <div class="obstacle-title">
                                            {{$t('transport_facility_scheme')}}
                                        </div>
                                        <div class="obstacle-img-input">
                                            <div class="obstacle-img">
                                                <img src="../../../assets/img/icons/scheme.svg" alt="">
                                            </div>
                                            <div class="obstacle-input">
                                                <label class="item">
                                                    <p> {{$t('transport_length')}} </p>
                                                    <el-form-item prop="structure_scheme.length">
                                                        <el-input :disabled="disabled_input" v-model="form.structure_scheme.length" :placeholder="`${$t('transport_length')}`" clearable />
                                                    </el-form-item>
                                                </label>

                                                <label class="item">
                                                    <p> {{$t('transport_number_intermediate_devices')}} </p>
                                                    <el-form-item prop="structure_scheme.numbers">
                                                        <el-input :disabled="disabled_input" v-model="form.structure_scheme.numbers" :placeholder="`${$t('transport_number_intermediate_devices')}`" clearable />
                                                    </el-form-item>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <label class="item">
                                        <p> {{$t('transport_base_material')}}</p>
                                        <el-form-item prop="base_material">
                                            <el-select :disabled="disabled_input" v-model="form.base_material" class="w-full" clearable :placeholder="$t('transport_base_material')">
                                                <el-option v-for="(item, i) in dictionary.base_material" :key="i" :label="item.name_cyr" :value="item.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </label>
                                    <label class="item">
                                        <p> {{$t('transport_facility_repair')}}</p>
                                        <el-form-item prop="repaired.repaired_type">
                                            <el-select :disabled="disabled_input" v-model="form.repaired.repaired_type" class="w-full" clearable :placeholder="$t('transport_facility_repair')">
                                                <el-option v-for="(item, i) in dictionary.repaired_type" :key="i" :label="item.name_cyr" :value="item.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </label>
                                    <label class="item">
                                        <p> {{$t('transport_facility_repair_year')}}</p>
                                        <el-form-item prop="repaired.year">
                                            <el-date-picker name="construction_year" :picker-options="pickerOptions" :disabled="disabled_input" v-model="form.repaired.year" type="year" value-format="yyyy" :placeholder="$t('transport_facility_repair_year')" style="width: 100%">
                                            </el-date-picker>
                                        </el-form-item>
                                    </label>
                                    <label class="item">
                                        <p> {{$t('transport_facility_technical_condition')}} </p>
                                        <el-form-item prop="technical_condition">
                                            <el-select :disabled="disabled_input" v-model="form.technical_condition" class="w-full" clearable :placeholder="$t('transport_facility_technical_condition')">
                                                <el-option v-for="(item, i) in dictionary.technical_condition" :key="i" :label="item.name_cyr" :value="item.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </label>
                                    <label class="item">
                                        <p> {{$t('transport_facility_basis_changed')}} </p>
                                        <el-form-item prop="condition_of_supports">
                                            <el-select :disabled="disabled_input" v-model="form.condition_of_supports" class="w-full" clearable :placeholder="$t('transport_facility_basis_changed')">
                                                <el-option v-for="(item, i) in dictionary.condition_of_supports" :key="i" :label="item.name_cyr" :value="item.id">
                                                </el-option>
                                            </el-select>

                                        </el-form-item>
                                    </label>
                                    <label class="item">
                                        <p> {{$t('transport_anti_seismic_struts')}} </p>
                                        <el-form-item prop="anti_seismic_struts">
                                            <el-select :disabled="disabled_input" v-model="form.anti_seismic_struts" class="w-full" clearable :placeholder="$t('transport_anti_seismic_struts')">
                                                <el-option v-for="(item, i) in dictionary.anti_seismic_struts" :key="i" :label="item.name_cyr" :value="item.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </label>
                                </el-col>
                                <el-col :md="8">
                                    <div class="item border-t-2 pt-5">
                                        <FileUpload v-model="form.passport_and_diagnosis" :name="$t('transport_diagnostic_doc')"  :disabled="true" />
                                    </div>
                                    <div class="item border-t-2 pt-5">
                                        <FileUpload v-model="form.general_photos" :name="$t('transport_all_status_doc')" :disabled="true" />
                                    </div>
                                    <div class="item border-t-2 pt-5">
                                        <FileUpload v-model="form.areas_where_damage" :name="$t('transport_damange_doc')" :disabled="true" />
                                    </div>
                                    <div class="item border-t-2 pt-5">
                                        <FileUpload v-model="form.strengthening_works_images" :name="$t('transport_tex_works_doc')" :disabled="true" />
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                    </el-form>
                </el-collapse>
            </div>
            <el-row class="flex items-end justify-center mb-3" :gutter="20">
                <el-col class="mt-5" :span="8">
                    <label class="item" v-if="finalconclusion_cyr">
                        <p class="mTitle"> {{$t('reconstructionsecondstep_finalconclusion')}} </p>
                        <el-alert class="mt-2" :title="finalconclusion_cyr" :type="final_type" show-icon="" :closable="false">
                        </el-alert>
                    </label>
                </el-col>
            </el-row>
            <div class="save-cancel-btn">  
                <div class="save-btn" v-if="['tf_operator'].includes(role) && this.form.is_user || 1==1">
                    <button class="button dark-blue" @click="$router.push(`/transport/edit/${$route.params.id}`)">
                        {{$t('change')}}
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div  v-if="modal"  class="modal fs-14" :class="modal?'active':''">
        <div class="modal-cover"></div>
        <div class="modal-item padding-map" style="width:95%; height:95%;">
            <div class="flex justify-between modal-absolute-header">
                <p class="modal-title "> {{disabled? `${$t('show_coor')}:`:`${$t('change_coor')}:`}}</p>
                <button style="font-size:16px; " @click="modal=false"><i class="el-icon-close" style="font-weight:600;"></i> </button>
            </div>
            <div class="w-full map-item h-full">
                <yandex-map @click="clicked" :coords="coords" style="height:100%; width: 100%" :zoom="14" :controls="['zoomControl']">
                    <ymap-marker :coords="coords" marker-id="123" hint-content="some hint" />
                </yandex-map>
            </div>
            <div class="flex w-full justify-between modal-bottom fw-w">
                <div class="flex items-center modal-inputes">
                    <p class="flex items-senter" style="font-size:16px;"><b class="mr-2">{{$t('coor')}}:</b> {{coords.join()}} </p>
                </div>
                <div class=" modal-inputes flex justify-center">
                    <button @click="modal=false" style="padding:15px 15px;"> <i class="el-icon-close mr-2"></i> {{$t('close')}}</button>
                    <button v-if="!disabled_input" class="ml-2" style="padding:15px 15px; background:green; " @click="SaveEditMap()"> <i class="el-icon-document-checked mr-2"></i> {{$t('save')}}</button>
                </div>
            </div>
        </div>

    </div>
</div>
</template>

<script>
import FileUpload from '@/components/FileUpload.vue'
import axios from 'axios';
export default {
    components: {
        FileUpload
    },
    computed: {
        dictionary() {
            return this.$store.state.dictionary;
        },

        role() {
            return this.$store.state.role;
        },
    },
    data() {
        return {
            activeNames: ['1'],
            selLoading: false,
            options: [],
            cadastor_data: {},
            current_district: [],
            coor: '',
            coords: [41.31629324503914, 69.26717199864542],
            road_name_options: [],
            road_name: "",
            disabled: true,
            modal: false,
            disabled_input: true,
            loading: false,
            finalconclusion_cyr: '',
            finalconclusion: {},
            final_type: 'info',
            final_types: {
                name_1: {
                    name: "I–сейсмик мустаҳкамлиги меъёрда",
                    type: 'success'
                },
                name_2: {
                    name: "II–сейсмик мустаҳкамлиги қониқарли",
                    type: 'info'
                },
                name_3: {
                    name: "III–сейсмик мустаҳкамлиги қониқарсиз, инструментал-техник текширувдан ўтказиш талаб этилади",
                    type: 'warning'
                },
                name_4: {
                    name: "IV–мустаҳкамлаш талаб этилади, авария ҳолатига мойил, авария ҳолатида",
                    type: 'error'
                },

            },
            form: {
                phone: '',
                email: '',
                website: '',
                region: '',
                district: '',
                road_name: '',
                km: '',
                shifr: '',
                type_transport_facility: '',
                year_construction: '',
                road_category: '',
                geolocation: '',
                barrier: {
                    barrier_type: '',
                    barrier_name: '',
                    water_basin: '',
                },

                type_static_schema: '',
                type_by_length: '',
                seismicity: '',
                intermediate_device_material: '',
                material_of_support: '',
                structure_scheme: {
                    length: '',
                    numbers: '',
                },
                size_of_building: {
                    traffic_section: '',
                    width_of_sidewalks: '',
                    meet_the_requirements: ''
                },
                base_material: '',
                repaired: {
                    year: '',
                    repaired_type: ''
                },
                technical_condition: '',
                condition_of_supports: '',
                anti_seismic_struts: '',
                passport_and_diagnosis: [],
                general_photos: [],
                areas_where_damage: [],
                strengthening_works_images: []
            },
            formrules: {
                phone: [{
                    required: true,
                    message: "Илтимос маълумот киритинг!",
                    trigger: "change",
                }, ],
                email: [{
                    required: true,
                    message: "Илтимос маълумот киритинг!",
                    trigger: "change",
                }, ],
                website: [{
                    required: true,
                    message: "Илтимос маълумот киритинг!",
                    trigger: "change",
                }, ],
                region: [{
                    required: true,
                    message: "Илтимос маълумот киритинг!",
                    trigger: "change",
                }, ],
                district: [{
                    required: true,
                    message: "Илтимос маълумот киритинг!",
                    trigger: "change",
                }, ],
                road_name: [{
                    required: true,
                    message: "Илтимос маълумот киритинг!",
                    trigger: "change",
                }, ],
                km: [{
                    required: true,
                    message: "Илтимос маълумот киритинг!",
                    trigger: "change",
                }, ],
                shifr: [{
                    required: true,
                    message: "Илтимос маълумот киритинг!",
                    trigger: "change",
                }, ],
                type_transport_facility: [{
                    required: true,
                    message: "Илтимос маълумот киритинг!",
                    trigger: "change",
                }, ],
                year_construction: [{
                    required: true,
                    message: "Илтимос маълумот киритинг!",
                    trigger: "change",
                }, ],
                road_category: [{
                    required: true,
                    message: "Илтимос маълумот киритинг!",
                    trigger: "change",
                }, ],
                geolocation: [{
                    required: true,
                    message: "Илтимос маълумот киритинг!",
                    trigger: "change",
                }, ],
                barrier: {
                    barrier_type: [{
                        required: true,
                        message: "Илтимос маълумот киритинг!",
                        trigger: "change",
                    }, ],
                    barrier_name: [{
                        required: true,
                        message: "Илтимос маълумот киритинг!",
                        trigger: "change",
                    }, ],
                    water_basin: [{
                        required: true,
                        message: "Илтимос маълумот киритинг!",
                        trigger: "change",
                    }, ],
                },

                type_static_schema: [{
                    required: true,
                    message: "Илтимос маълумот киритинг!",
                    trigger: "change",
                }, ],
                type_by_length: [{
                    required: true,
                    message: "Илтимос маълумот киритинг!",
                    trigger: "change",
                }, ],
                seismicity: [{
                    required: true,
                    message: "Илтимос маълумот киритинг!",
                    trigger: "change",
                }, ],
                intermediate_device_material: [{
                    required: true,
                    message: "Илтимос маълумот киритинг!",
                    trigger: "change",
                }, ],
                material_of_support: [{
                    required: true,
                    message: "Илтимос маълумот киритинг!",
                    trigger: "change",
                }, ],
                structure_scheme: {
                    length: [{
                        required: true,
                        message: "Илтимос маълумот киритинг!",
                        trigger: "change",
                    }, ],
                    numbers: [{
                        required: true,
                        message: "Илтимос маълумот киритинг!",
                        trigger: "change",
                    }, ],
                },
                size_of_building: {
                    traffic_section: [{
                        required: true,
                        message: "Илтимос маълумот киритинг!",
                        trigger: "change",
                    }, ],
                    width_of_sidewalks: [{
                        required: true,
                        message: "Илтимос маълумот киритинг!",
                        trigger: "change",
                    }, ],
                    meet_the_requirements: [{
                        required: true,
                        message: "Илтимос маълумот киритинг!",
                        trigger: "change",
                    }, ],
                },
                base_material: [{
                    required: true,
                    message: "Илтимос маълумот киритинг!",
                    trigger: "change",
                }, ],
                repaired: {
                    year: [{
                        required: true,
                        message: "Илтимос маълумот киритинг!",
                        trigger: "change",
                    }, ],
                    repaired_type: [{
                        required: true,
                        message: "Илтимос маълумот киритинг!",
                        trigger: "change",
                    }, ],
                },
                technical_condition: [{
                    required: true,
                    message: "Илтимос маълумот киритинг!",
                    trigger: "change",
                }, ],
                condition_of_supports: [{
                    required: true,
                    message: "Илтимос маълумот киритинг!",
                    trigger: "change",
                }, ],
                anti_seismic_struts: [{
                    required: true,
                    message: "Илтимос маълумот киритинг!",
                    trigger: "change",
                }, ],
                passport_and_diagnosis: [{
                    required: true,
                    message: "Илтимос маълумот киритинг!",
                    trigger: "change",
                }, ],
                general_photos: [{
                    required: true,
                    message: "Илтимос маълумот киритинг!",
                    trigger: "change",
                }, ],
                areas_where_damage: [{
                    required: true,
                    message: "Илтимос маълумот киритинг!",
                    trigger: "change",
                }, ],
                strengthening_works_images: [{
                    required: true,
                    message: "Илтимос маълумот киритинг!",
                    trigger: "change",
                }, ],
            },
            deniedKeys: ['structure_scheme', 'size_of_building', 'repaired', 'barrier', 'passport_and_diagnosis', 'general_photos', 'areas_where_damage', 'strengthening_works_images'],
            arrayKeys: ['passport_and_diagnosis', 'general_photos', 'areas_where_damage', 'strengthening_works_images'],
            objectKeyd: ['structure_scheme', 'size_of_building', 'repaired', 'barrier'],
            districts: [],
            pickerOptions: {
                disabledDate(date) {
                    return date > new Date('2022');
                },

            },
        }
    },
    watch: {
        'form.region'(val) {
            axios.get(`/dictionary/district/?region=${val}`)
                .then((response) => {
                    this.districts = response.data;
                })
        },
        'form.road_name'() {
            this.getRoadName()
        },
        'form.road_name'(val) {

            axios.get(`/dictionary/road_name_option/?road_name=${val}`)
                .then((response) => {
                    this.road_name_options = response.data.results;
                })
        }

    },
    methods: {
        EditMap() {
            this.modal = true
            this.coords = this.coor
        },
        SaveEditMap() {
            this.modal = false
            this.coor = this.coords.join()
            this.form.geolocation = this.coords;
        },
        clicked(e) {
            if (!this.disabled_input) {
                this.coords = e.get("coords");
            }
        },
        getRoadName() {
            axios.get(`/dictionary/road_name/?search=1`)
                .then((response) => {
                    this.options = response.data.length > 0 ? response.data : []
                })
                .catch(() => {
                    this.options = []
                })
                .finally(() => {
                    this.selLoading = false;
                })
        },
        remoteMethod(query) {
            if (query !== '') {
                this.selLoading = true
                axios.get(`/dictionary/road_name/?search=${query}`)
                    .then((response) => {
                        this.options = response.data.length > 0 ? response.data : []
                    })
                    .catch(() => {
                        this.options = []
                    })
                    .finally(() => {
                        this.selLoading = false;
                    })
            } else {
                this.options = [];
            }
        },
        _Get() {
            this.loading = true
            axios.get(`/transport_facility/${this.$route.params.id}/`)
                .then(res => {
                    this.form = res.data
                    this.coor = res.data.geolocation
                    this.form.road_name = parseInt(res.data.road_name)
                    this.form.repaired.year = String(res.data.repaired.year)
                    this.form.year_construction = String(res.data.year_construction)
                    this.getroadName(res.data.road_name)
                    if (res.data.finalconclusion) {
                        this.finalconclusion_cyr = this.final_types[`name_${res.data.finalconclusion}`].name;
                        this.final_type = this.final_types[`name_${res.data.finalconclusion}`].type;
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },
        getroadName(id) {
            axios.get(`/dictionary/road_name/${id}/`)
                .then(res => {
                    this.options.push(res.data)
                    this.road_name = res.data.name
                })
        }

    },
    mounted() {
        this._Get()
    }

}
</script>

<style lang="scss" scoped>
.save-cancel-btn {
    display: flex;
    align-items: center;
    justify-content: right;
    margin-top: 15px;
}

.cancel-btn {
    margin-right: 10px;
}

.wrapper {
    .wp-body {
        .t-title {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 600;
            font-size: 30px;
            line-height: 37px;
            color: #004787;
        }

        .general-info {
            .general-info-title {
                font-family: 'Montserrat';
                font-style: normal;
                font-size: 18px;
                font-weight: 600;
                line-height: 37px;
                color: #004787;
                margin-top: 34px;
                margin-bottom: 15px;
            }

            hr {
                background: #004787;
                height: 2px;
            }

            .coll-title {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 20px;
                color: #004787;
                margin-top: 30px;
            }

            .obstacle {
                .obstacle-img-input {
                    display: flex;
                    align-items: center;
                }

                .obstacle-title {
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 20px;
                    color: #004787;
                    margin-left: 65px;
                    margin-top: 18px;
                }

                .obstacle-img {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 160px;
                    background: #D4D4D4;
                    border-radius: 6px;
                    margin-right: 10px;
                    padding: 0px 5px;
                }

                .obstacle-input {
                    width: 100%;
                }
            }

            .transport-location {
                display: flex;
                align-items: center;

                .transport-location-img {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 327px;
                    background: #D4D4D4;
                    border-radius: 6px;
                    margin-right: 10px;
                    padding: 0px 5px;
                }

                .transport-location-input {
                    width: 100%;
                }
            }
        }
    }
}
</style><style lang="scss">
.item-transport {
    .el-form-item__content {
        display: flex;
        align-items: center;

        .coll-icon {
            background: #D4D4D4;
            border-radius: 6px 0px 0px 6px;
            width: 42px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        input {
            border: 1px solid #D4D4D4;
            border-radius: 0px 6px 6px 0px;
            border-left: unset;
        }
    }
}

.coll-place {
    input::placeholder {
        color: #8EACB2;
    }
}
</style>
